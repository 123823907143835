import React from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import Layout from '../components/layout'
import SEO from '../components/seo'

const Finder = styled.iframe`
  border: 0;
  width: 100%;
  height: 600px;
`

const BeerFinder = ({ data }) => (
  <Layout bgImage={data.contentfulBeerPage.backgroundImage}>
    <SEO title="Beer Finder" />
    <h1>Where To Find Our Products</h1>
    <Finder src="https://finder.vtinfo.com/finder/web/v2/iframe?custID=N79&amp;UUID=PCLZfIsY496347KTQuhrB3hHiHhYi2Hwf5Fq" />
  </Layout>
)

export default BeerFinder

export const query = graphql`
  query BeerFinderQuery {
    contentfulBeerPage {
      backgroundImage {
        file {
          url
        }
        fluid(maxWidth: 1024) {
          src
          srcSet
          sizes
          srcSetWebp
          srcWebp
          base64
          aspectRatio
        }
      }
    }
  }
`
